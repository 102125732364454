
import { defineComponent } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import { getDoc, getFirestore, updateDoc, doc } from "firebase/firestore"
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage"

export default defineComponent({
  name: "file",
  props: {
    fileTitle: String,
    createdAt: String,
    fileType: String,
    fileUri: String
  },
  components: {},
  methods: {
    // async kycremind(doc){
    //   try{

    //     updateDoc(doc(getFirestore(), this.$route.params.uid), {
    //       selfie: null,
    //       id: null
    //     })

    //     await this.axios.post(`/notification/${this.$route.params.uid}`, {
    //       title: 'Action Required!',
    //       body: `You have received a request to re-submit your ${doc}. Submit now to start investing with InvestSika`
    //     })
    //     Swal.fire({
    //         text: 'A push notification has been sent to the user to request them to re-submit their documents.',
    //         icon: "success",
    //         buttonsStyling: false,
    //         confirmButtonText: "Okay",
    //         customClass: {
    //             confirmButton: "btn fw-bold btn-light-success",
    //         },
    //     })
    //   }catch(e){
    //     Swal.fire({
    //         text: e.message,
    //         icon: "error",
    //         buttonsStyling: false,
    //         confirmButtonText: "Try again",
    //         customClass: {
    //             confirmButton: "btn fw-bold btn-light-danger",
    //         },
    //     })
    //   }
    // },
    // async upload(e){
    //   let file = e.target.files[0]

    //   let storage = getStorage()
    //   const storageRef = ref(storage, file.name);

    //   // 'file' comes from the Blob or File API
    //   uploadBytes(storageRef, file).then(async(snapshot) => {
    //     let url = await getDownloadURL(snapshot.ref);
    //     // this.fileUri = url
    //     try{
    //       this.axios.post(`/compliance/${this.$route.params.uid}`, {
    //         doc: e.target.id,
    //         url
    //       })
    //       Swal.fire({
    //         text: `The ${e.target.id} was submitted successfully!`,
    //         icon: "success",
    //         buttonsStyling: false,
    //         confirmButtonText: "Alright",
    //         customClass: {
    //             confirmButton: "btn fw-bold btn-light-primary",
    //         },
    //       })
    //       setTimeout(()=>this.$router.go(0), 2000)
    //     }catch(e){
    //       Swal.fire({
    //         text: e.message,
    //         icon: "error",
    //         buttonsStyling: false,
    //         confirmButtonText: "Try again",
    //         customClass: {
    //             confirmButton: "btn fw-bold btn-light-danger",
    //         },
    //       })
    //     }
    //   });

    // }
  }
});
